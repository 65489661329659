/*
 * Flex UX Framework
 *
 * Filename: terms.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.terms {
	main {
		background: #fff;
	}

	.page-body {
		max-width: 600px;
		margin: auto;
    	margin-top: auto;
		padding: 12px;
		margin-top: 9px;

		@include mq("phone-wide", "max"){
			padding: 15px;
    		margin-top: 0;
		}
	}

	h1:not(:first-of-type) {
		font-size: 23px;
		font-family: $secondary-font-face;
		letter-spacing: 0;
		font-weight: normal;
		text-transform: none;
		margin-bottom: 5px;
    }

    p {

    	font-size: 13px;
    	line-height: 21px;
    	margin-bottom: 20px;
	}
}