/*
 * Flex UX Framework
 *
 * Filename: home-layout-v3.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v3{
 	main{
	 	.featured-spotlight{
				width: 101%;
			.page-header{
				display: flex;

				@include mq("tablet", "max") {
					flex-flow: column nowrap;
				}

				@include mq('phone', 'max') {
					height: 100%;
				}

				#main-featured-product{
					flex: 0 0 50%;

					.product-list:first-of-type{
						height: 100%;
						.product-card{
							justify-content: center;

							.img-container{
								max-height: 100%;								
								.product-image{
									max-height: 400px !important;
									max-width: 400px !important;
									width: 100%;

									@include mq("tablet", "max"){
										max-height: 200px !important;
										max-width: 200px !important;
									}

									// @include mq("phone", "max"){
									// 	max-height: 125px !important;
									// 	max-width: 125px !important;										
									// }
								}
							}
						}
					}
				}

				.product-list{
					width: 100%;
					margin: 0px;
					.product-card{
						min-height: 259px;
						max-width: none;
						flex-basis: 50%;
						// min-width: 200px;
						margin: 0px;
						border-right: 1px solid #f2f2f2;
						border-bottom: 1px solid #f2f2f2;
						border-radius: 0;

						.product-image{
							flex:inherit;
						}
					}
				}
			}
	 	}

	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

	 		#featured-3{
	 			h1{
	 				margin: 10px;
	 				text-align: center;
	 			}
	 		}

			.about-us{
				background: #ffffff;
				margin: 0px;
				border-radius: 0px;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}

 	}
 }