/*
 * Flex UX Framework
 *
 * Filename: item-options.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {

	&-options-container {
    	position: relative;
    	box-sizing: border-box;
    	border: 1px solid rgba(226,226,226,0.5);
    	padding: 20px;
    	margin-top: 30px;

    	@include mq("tablet", "max"){
    		padding: 10px;
    	}
    }

    &-quantity-details {
    	display: flex;
    	align-items: center;

    	.product-quantity {
    		min-width: 60px;
			width: 60px;
			margin-right: 25px;

			form {
				display: flex;
				align-items: center;

				&:before {
					content: "QTY: ";
					margin-right: 5px;
					color: #353535;
					font-size: 14px;
					font-weight: normal; 
				



				}
			}
    	}
	}

	&-image-option{
		&-title{
		    text-transform: uppercase;
		    font-family: $standard-font-face;
		    font-size: 13px;
		    margin-bottom: 10px;
	    	font-weight: normal;
		}
		&-name{
			color: $brand-color;
		}
		&-thumbnails {
		    display: flex;
		    margin-left: -5px;
		    flex-wrap: wrap;
		}

		&-thumbnail{
			height: 70px;
		    width: 70px;
		    margin: 5px;
		    text-align: center;
		    cursor: pointer;

			&.active, &:hover {
		    	outline: 2px solid $brand-color;
			}
		}
	}
}

.product-quantity-details  {
	.product-quantity{ 
		form{
    		margin-bottom: 0;
			input{
		    	font-size: 13px;
		    	font-family: $standard-font-face;
		    	color: #000;
			}	
		} 

		.mdl-textfield.is-dirty {
		    color: #333;
		}

		.mdl-textfield {
		    color: #BFBFBF;
		}

		.mdl-textfield.is-dirty .mdl-textfield__label {
		    color: $brand-color;
		}
	}

	.quantity-available {
	    font-weight: normal;
	}

	.remove-btn {
	    margin-left: auto;
	    font-size: 11px;
	    letter-spacing: 2px;
	}

}
