/*
 * Flex UX Framework
 *
 * Filename: item-romance-mobile.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product-description-text {
    font-family: $standard-font-face;
    line-height: 1.8;

    th {
    	color: $primary-font-color;
    }

    td {
    	color: $brand-color-secondary; 
    	font-weight: bold !important; 
    }

	@include mq("tablet", "max"){
		line-height: 2;
	}
}

.product-description-accordions {
	@include mq("tablet", "max"){
		line-height: 2;
	}

	@include mq('phone-wide', 'min'){
		height: 0;
	    overflow: hidden;
	}

	.product-description-text{
		padding: 15px;
	}

	.mdl-accordion > .mdl-button {
	    width: auto !important;
	    font-weight: 700;
	    font-family: $standard-font-face;
	}

}