/*
 * Flex UX Framework
 *
 * Filename: home.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home {

	&__comingsoon {

		background: #00205B; 
 
		main {
			background-color: unset !important;
			.page-body {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100vh; 
				flex-direction: column;

				img {
					max-width: 470px; 
					padding-top: 20px; 
				}

				h1 {
                    font-size: 60px;
                    color: white;
                    font-weight: 700;
                    text-align: center; 
                    line-height: 60px;  
				}  

				
			}
		}
	}
	.about-us {
    	margin: 12px 12px 32px 12px;

    	@include mq("phone-wide", "max"){
			margin: 8px !important;  
		}
	}

	.categories { 
		display: flex; 
		flex-direction: row;
		justify-content: center;
		align-items: center;  
		margin-bottom: 24px; 

		@include mq('phone-wide', 'max') {   
			flex-direction: column;  
			margin: 0px 5px;
		}

		 
		a {

			flex: 1;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-end;
			min-height: 254px;
			border-radius: 7px;
			max-width: 463px; 
			height: 254px;
			text-decoration: none;
 
			 &:nth-of-type(1) {
                margin-right: 12px;
			}
		     &:nth-of-type(2) {
				margin-left: 12px;  
			 }  

			@include mq('tablet', 'max') {
			 
			 	margin: 5px !important; 
			 	min-height: 183px; 
				
			}

			@media only screen and (min-width: 920px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
                min-width: 463px; 
			}


			@include mq('phone-wide', 'max') {  
			 	width: 100%;  
			 	min-width: 100%; 
			}

			@include mq('tablet-wide', 'min') {  	  
			}

			&:hover {
				text-decoration: none; 
				opacity: 0.9; 
			}  

			h1 {
				color: white;  
				font-size: 45px;
				text-shadow: 0 0 25px 0px #00245D; 
				display: inline-block;
				position: relative;
				line-height: 1;
				padding-right: 26px; 
				text-transform:unset;
				text-align: right;
			}

		}
	}


}