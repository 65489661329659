/*
 * Flex UX Framework
 *
 * Filename: sort-by.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


#sort-by.btn {
    align-self: flex-end;
    padding: 3px 7px;
    padding-right: 10px;
    letter-spacing: 0;
    font-size: 14px;
    color: #353535;
    line-height: normal;
    text-transform: uppercase;
    font-weight: normal;
    order: 2;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    // flex-basis: 92px;

    @include mq('tablet', 'max') {
    	padding: 5px 7px;
	    font-family: $standard-font-face;
	    border-radius: 5px;
	    order: 3;
    }

    @include mq('tablet', 'min') {
    	padding: 5px 15px;
	    min-width: 85px;
	    // height: 41px;
	    font-family: $standard-font-face;
    }

    &::after {
    	font-family: 'Material Icons';
		content: '\e314';
		display: inline-block;
		transform: rotate(-90deg);
		font-size: 22px;
		color: $brand-color;
		position: relative;
    }

}

.sort-by-container {
    .mdl-menu__container {
        left:initial !important;
        top:initial !important;
        margin-left: -36px;

        @include mq('tablet', 'max') {
            margin-left: -61px;
        }
    }
}

#sort-by-menu {
    .mdl-menu__item {
        a {
            color: #353535;
        }
        &:hover {
            background-color: #F2F2F2;
        }
    }
}