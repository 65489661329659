/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home-contact-us {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #9AC5E5; 

    &__content {
		max-width: 948px;
		text-align: center;
		box-sizing: border-box;
	    background: white; 
		flex: 1 1 100%;
		padding: 14px; 
		margin: calc(12px + 12px);
    	margin-right: calc(24px);
    	margin-left: calc(24px);
		margin-left: calc(12px + 12px + 12px);
		margin-right: calc(12px + 12px + 12px);
		border-radius: 5px;
		font-family: $standard-font-face;
		box-shadow: $box-shadow; 

		&__wrapper {
			display: flex; 
			flex-direction: row;
			justify-content: center;
			align-items: center; 

			@include mq("phone-wide", "max") { 
				flex-direction: column; 
			}
		}



		.contact-us-grid {
			padding: 0;
    	}

		h1 {
			padding-bottom: 0;
			padding-top: 10px;
			font-weight: normal;
			color: #00245D;
			font-family: $standard-font-face;
			font-size: 30px;
			text-align: center;  
		}

    	p {
			margin-top: 12px;
			margin-bottom: 8px;
			padding-left: 30px;
			padding-right: 30px;
			color: #353535;
			font-family: $secondary-font-face;
			font-size: 14px;
			font-weight: normal;
			line-height: 21px;
			text-align: center; 
			max-width: 700px;
    	}

    	a {
    		font-size: 14px; 
//     		text-decoration: none;
// 			color: $brand-color;
// 			line-height: 24px; 
// 			font-weight: bold;
    	}
    }
    .home-contact-us__content {
		@include mq("phone-wide", "max"){
	    	margin: 6px;
	    }
	}

}