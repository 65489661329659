/*
 * Flex UX Framework
 *
 * Filename: myaccount_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.mdl-tabs__tab {
    padding: 0px 15px;
    transition: background 0.15s;
    font-family: $standard-font-face;
    border: 1px solid #F2F2F2;
    background-color: #FFF;
    height: 45px;
    height: 48px;
    font-size: 12px;
    font-family: $standard-font-face;
    border: 0;
    color: rgba(0,0,0,.54);
    font-weight: 700;

   @include mq("phone-wide", "max"){
        padding: 0 12px;
    }

    &::after {
   		background: $brand-color !important;
    	height: 2px;
    }
}
