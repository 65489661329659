/*
 * Flex UX Framework
 *
 * Filename: home-featured-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// @TODO: rename markup
// @TODO: convert @media queries to use the mq mixin

.main-feature {
//    @media only screen and (max-width: 767px) and (min-width: 400px) {
//     height: 275px !important;
//     min-height: 275px !important;
//     max-height: 275px !important;
//   }

//   @include mq("tablet-small", "max") {
//     height: auto;
//     padding: 10px 38px 38px;
//     flex-flow: column nowrap;
//   }

//   @include mq("phone-wide", "max") {
//     margin: 6px;
//   }

//   height: 300px;
//   margin: 12px;
//   display: flex;
//   flex-flow: row nowrap;
//   padding: 0;
//   border-radius: 5px;
//   overflow: hidden;


}
