/*
 * Flex UX Framework
 *
 * Filename: spotlight_slider.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */ 

.page-header .hero-carousel-overlay .hero-text {
    font-family: $standard-font-face;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 258px;
    letter-spacing: 4px;
    margin-left: 159px;
    color: white;
    font-size: 115px;
    font-weight: 600;
    line-height: 0.8;
    text-transform: uppercase;
}

.hero-carousel {
    width: 100%;
    max-width: 100%;
    height: 450px !important;
	min-height: 450px !important;
	max-height: 450px !important;
	margin-bottom: 0;

    .slick-slider .slick-track, .slick-slider .slick-list {
    	-webkit-transform: translate3d(0, 0, 0);
    	-moz-transform: translate3d(0, 0, 0);
    	-ms-transform: translate3d(0, 0, 0);
    	-o-transform: translate3d(0, 0, 0);
    	transform: translate3d(0, 0, 0);
	}

	.slick-track {
		opacity: 1;
		width: 1354px;
		position: relative;
		top: 0;
		left: 0;
		display: block;

		.hero-slide {
			background-image: url("store/20190204969/assets/images/spotlights/HOME_desktop_450_1024.jpg");
			width: 1354px;
			position: relative;
			left: 0px;
			top: 0px;
			z-index: 999;
			opacity: 1;
			background-position: center;
			background-size: cover;
			overflow: hidden;
			display: flex;
		}
	}

	.slick-track::before, .slick-track::after {
		display: table;
		content: '';
	}

}
