/*
 * Flex UX Framework
 *
 * Filename: home-featured-banner-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .home .main-feature {
//     background: url("../../../images/spotlights/SAFEWORK_desktop_295_1024.png") no-repeat scroll center;
//     background-image: url("../../../images/spotlights/SAFEWORK_desktop_295_1024.png");
//     background-size: cover;
    
// 	.product-info {
//     	text-align: center;
// 		display: flex;
// 		flex-flow: column nowrap;
// 		align-items: center;
// 		justify-content: center;
// 		max-width: 350px;
// 		margin: auto auto;
// 		align-self: flex-start;

// 		  @include mq("tablet-small", "max") {
// 		    margin: auto auto 0;
// 		  }
//     }

//  	> div {
// 	    width: 50%;
//     	display: flex;
//     	flex-flow: column nowrap;
// 		@include mq("tablet-small", "max"){
// 		    width: 100%;
// 		    height: 100%;
// 		}

//     }

//     h1 {
// 		color: #fff;
// 		font-family: $standard-font-face;
// 		text-shadow: 2px 2px 7px #333;
// 		font-size: 70px;
// 		line-height: 70px;

// 		@include mq("tablet", "max"){
// 			position: relative;
// 		    top: -8px;
// 		    font-size: 48px !important;
// 		    line-height: 43px;
// 		}

//     	@include mq("desktop", "min"){
// 		    position: relative;
// 		    top: -8px;
// 		    font-size: 70px;
// 		    line-height: 70px;
// 		}
// 	}
	
	 display: flex;
     flex-direction: row;
   	 align-items: center;
   	 justify-content: center;
   	 max-width: 948px;
   	 margin: 0 auto;

 	 @include mq("tablet-smaller", "max") {
   		 
   		 flex-direction: column-reverse;
   		 max-width: 584px;
   	}
   	 
 


	&__text {
		
		flex: 1;
		display: flex;
   		flex-direction: column;
		flex: 1; 
		max-width: 309px;
    	padding-left: 50px;
    	 @include mq("tablet-smaller", "max") {
			padding: 30px;
    	 }

		h1 {
			color: #157C70;
			font-size: 42px;
			font-weight: 300;
			text-transform:capitalize; 
			font-size: 4vw;

		}
		p { 
			color: #766F6A;
			font-size: 18px;
			font-weight: 300;
		}
	}

   		 &__img {
			flex: 1;
			background: url(../images/triangle-yellow.png);
			background-size: cover;
			height: 100%;
			display: flex; 
    		justify-content: center;
    		position: relative;
    		width: 100%;
    		
			img {
    			min-height: 100px;
			}
// 			&__overlay {
// 				flex: 1;
// 				background: url(../images/spot-pine.png);
// 				background-size: cover;
// 				height: 61%;
// 				position: absolute;
// 				width: 72%;
// 				bottom: 0;
// 				right: 0;

// 			}
   		 }



}

body.home h1{
	margin: 0;
	
// 	@include mq("tablet", "max"){
//     	font-size: 22px !important;
// 	}
	
}


.home .product-info--right {
  @include mq("tablet", "min"){
	    align-self: flex-end !important;
	    margin-right: 70px;
	}
}	


