/*
 * Flex UX Framework
 *
 * Filename: checkout_multibilltoaddr.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
    display: flex;
    flex-wrap: wrap;

    // @include mq("tablet", "min") {
    // 	margin: 20px;
  	// }

  	i {

  		&.material-icons {
  			color: $brand-color;
  		}
  	}

  	.checkout-main {
  		display: flex;
		flex-flow: column;
        width: 100%;


      	.checkout-basket-promo {
      		display: flex;  
      		padding: 24px;  
      		flex-direction: column;
			background: #FFF;

      		
      		@include mq("phone-small", "min") {
			//     align-items: flex-start;  
			}   

		     
		     button {
		     	max-width: 200px; 
		     }
      		 
          

      		.text-wrapper {
      			display: flex; 
      			align-items: center;
      			max-width: 300px;
      			 .mdl-textfield {
      			 	width: 100% !important; 
      			 	margin: 0px 15px;  
      			 } 
      		}
      	}



		@include mq("tablet", "min") {
    		flex: 75 1;
			margin-right: 20px;
  		}

  		&-top {
  			margin-bottom: 20px;
			background: #FFF;
  		}

  		&-bottom {
  			flex: 1 1 100%;
			background: #FFF;
  		}

      p{
        font-size: 13px;
        line-height: 20px;
		font-weight: normal !important;
		word-break: break-word;
		white-space: normal;
      }
  	}

  	.checkout-side {
  		flex: 25 1;
        @include mq("tablet", "max") {
          margin-top: 10px;
        }
  		&-top {
  			flex-flow: column;
			   padding: 0;

			&--addresses {
				padding: 20px!important;

				h2 {
					margin-bottom: 0;
					padding-left: 0;
					padding-right: 0;
					padding-top: 0;
					padding-bottom: 3px !important;
				}
			}
  		}

  		&-bottom {
  			display: flex;
			flex: 1 1 100%;
			flex-flow: column;
			padding: 0;
			margin-top: 20px;
  		}
  	}
}

