/*
 * Flex UX Framework
 *
 * Filename: home-layout-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v1{
 	main{
 		background-color: #f2f2f2; 
	 	.spotlight{
			.page-header{
				display: flex;
				flex-flow: nowrap;

				@include mq("tablet", "max"){
					flex-direction:column-reverse;
					height: 100% !important;
				    min-height: unset !important;
				    max-height: unset !important;
				}

				&>div{
					&:first-of-type{
						height: inherit;
						@include mq("tablet", "max"){
							height: 287px;
						}
					}
				}

				.hero-carousel.slick-initialized.slick-slider{
					@include mq("tablet", "max"){
						height: 287px !important;
					}	
				}

				.spotlight-hero{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					
					position: absolute;
					z-index: 1;
					box-sizing: border-box;
					
					height: inherit;
					width: 287px;
					opacity: 0.95;
					padding: 40px 20px 20px;
					background-color: #53565A;
					color: white;
					font-size: 70px;

					@include mq("tablet", "max"){
						position:inherit;
					   height: 287px;
					   width: 100%;
					   opacity: 1;
					}

					@include mq("phone-wide", "max"){
						font-size: 60px;
					}

					&>div{
						display: flex;
						line-height: 0.8;
						&:first-of-type{
							font-size: 20px;
						}
					}

					button{
						// margin-top: 50px;
						color: white;
						background-color: $brand-color-btn-primary;
						min-height: 40px;
					}
				}
			}
	 	}

	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

			#featured-1{
				.product-list{
					width: 101%;
					margin: 0px;
					.product-card{
						min-height: 259px;
						max-width: none;
						flex-basis: 20%;
						min-width: 200px;
						margin: 0px;
						border-right: 1px solid;
    					border-bottom: 1px solid;
						border-radius: 0;

						&:first-of-type {
					        justify-content: space-around;
							&>h1{
								font-size: 28px !important;
							}
						}
						@include mq("phone-wide", "max"){
							min-width: 160px;
							&:nth-child(n+3){
								display: inherit;
							}
							&:nth-child(n+7){
								display: none;
							}

						}

						.product-image{
							flex:inherit;
						}
					}
				}
			}

			.about-us{
				background: white;
				max-width: 964px; 
    			margin: 24px auto;  
//     			box-shadow: $box-shadow; 
				div.btn--primary{
					display: flex;
				}


				



			}	
	 	}

 	}
 }