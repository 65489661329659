/*
 * Flex UX Framework
 *
 * Filename: category_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.sub-nav {

	.sub-nav-item {
    	position: relative;

	}

	.nav-handle {
		padding: 0 24px;
	}

}

.breadcrumb {
    a, span {
        
        margin-right: 15px;
        text-decoration: none;
        z-index: 1;
		color: #353535;
		font-family: $secondary-font-face;
		font-size: 13px; 
    }
}

