/*
 * Flex UX Framework
 *
 * Filename: myaccount-changepassword.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.forgot-password {
    .page-body {
        max-width: 600px;
    }
    p {
        text-align: center;
    }
    form {
        display: flex;
        flex-direction: column;
        .mdl-textfield {
            margin: 0 auto;
        }
        button {
            width: 300px;
            margin: 10px auto 0;
        }
        .mdl-textfield__label {
            top: 18px;
            .material-icons {
                position: relative;
                top: 4px;
            }
        }
    }
}