/*
 * Flex UX Framework
 *
 * Filename: general.scss
 * Type:     General Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

//Universal Element Styles


html {
    height: 100%;
    color: $primary-font-color;
    border: 0;      
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    height:  auto !important;
    min-height: 100%;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    font-family: $standard-font-face;
    line-height: 1;
    font-size: 13px;
    // background: #F7F7F6;
    background: #FFF;
}

p {
	color: #353535;
	font-family: 'ESKbook', sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 21px; 
}

input {
    -webkit-appearance: none;
    font-family: $standard-font-face;
    color: #000;
    line-height: 1;
    font-size: 13px;
}

// Hide X from search bar for IE/Chrome.
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

body, input {
    @include mq("tablet", "max") {
        font-size: 13px;
    }
}

a {
    cursor: pointer;
	font-size: 14px;
	// text-decoration: none;
	color: #0033A0;
	font-family: $secondary-font-face;
    font-weight: normal;
	

	&:hover {
		color: #006D7A;
		text-decoration: underline;
		color: #00205B;
	
	}
} 

img {
    max-width: 100%;
    max-height: 100%;   
}

h1 {
    line-height: 1.35;
    font-family: $standard-font-face;
    color: $h1-font-color;
    font-weight: normal !important; 
    font-size: $h1-font-size;
}

h2 {
    margin: 0;
    line-height: 1.2;
    font-family: $standard-font-face;
    color: $h2-font-color;
    letter-spacing: 0;
    font-weight: normal;
    text-transform: uppercase;
    font-size: $h2-font-size;

    @include mq("tablet", "max") {
        font-size: 20px;
    }
}


@include mq('phone-wide', 'max') {
    .desktop-only {
        display: none !important;
    }
}

@include mq('phone-wide', 'min') {
    .mobile-only {
        display: none !important;
    }
}

//Universal Page Styles
.page-body {
    max-width: $body-max-width;
    padding: 7px 12px 12px;
    margin: 9px auto auto;

    &>h1:first-of-type {
        font-weight: 900;
        padding-top: 10px;
        padding-bottom: 20px;
        text-transform: capitalize;
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 20px;
        margin: 10px 0 0;
    }
}

.product-card, .product-cell {
    margin: 0 12.5px 24px 12.5px; 

    @include mq("phone-wide", "max") {
        margin: 6px;
    }

    @include mq("phone", "max") {
        margin: 5px;
    }
}

.product-list {
    margin: 0 -12px;

    @include mq("tablet", "max") {
        justify-content: center;
    }
    @include mq("phone-wide", "max") {
        margin: 0 -6px;
    }
    @include mq("phone", "max") {
        margin: 0;
    }
}

.grid {
    @include mq("tablet", "min") {
        margin: 0 !important;
    }
}

.btn--light {
    transition: background 0.15s;
    font-family: $standard-font-face;
    border: 1px solid #F2F2F2;
    background-color: #F2F2F2;
    height: 45px;
    color: #000;
    font-weight: normal;
    letter-spacing: 1px;
}

.btn--dark {
    background: black;
    color: white;
}

.btn--dark, .btn--light {
    letter-spacing: 1px;  
    padding: 3px 51px;
    font-size: 16px;
    // line-height: 1;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important; 
}

.btn--primary, .btn--secondary {
    font-size: 16px;
    font-family: $standard-font-face;
    color: #fff;
	padding: 0px 24px;
    transition: background 0.15s;
    height: 45px;
    font-weight: normal;
    letter-spacing: 1px; 

    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px; 
    
}
 
.btn--primary{
    background-color: $brand-color-btn-primary;
	color: white;
	font-weight: normal; 

    &:hover {
        background-color: $brand-color-btn-primary-hover !important;
    }
}

.btn--secondary {
    background-color: $brand-color-btn-secondary;
   
	color: white;

    &:hover {
        background-color: $brand-color-btn-secondary-hover; 
    }

    .mdl-ripple {
        background: #8a8c8e !important;
        background-color: rgb(138, 140, 142);
        background-color: #8a8c8e !important;
    }
}

// Material Design Overrides
.mdl-layout--fixed-drawer>.mdl-layout__drawer-button {
    @include mq("tablet", "min") {
        display: none;
    }
}

.mdl-layout__container {
    pointer-events: none;
}

.mdl-textfield{
    .mdl-textfield__label:after {
        background-color: $brand-color !important;

    } 

    &--floating-label.is-dirty, &--floating-label.is-focused{
        .mdl-textfield__label{
            color:$brand-color !important;
        }
    }

    &__input {
        padding-left: 3px;
        font-family: $secondary-font-face;
        font-size: 14px !important;
        color: $primary-font-color;
    }
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple{
    background-color: #000;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline{
    background-color: $brand-color;
}

.checkout{
    .page-body{
        h1:first-of-type{
            margin-left: 10px;
        }  
    }
}


/* 
 * Modal Dialogs
 */

.mdl-dialog {
    width: calc(100% - 80px);
    max-width: 760px;
    
    p{
      color:$primary-font-color;
    }

    a{
      color:$brand-color;
    }

    .close-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px;
        .close-btn {
            color: $brand-color;
        }
    }

    .modal-title {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #efeff1;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 55px;
        font-size: 23px;
        text-transform: uppercase;
        line-height: 1.75;
        height: auto;
        .modal-title{

            &__major {
                flex: 1 1 100%;
                align-self: flex-end;
                line-height: 1;
                text-align: center;
                font-size: 24px;
                text-transform:capitalize;
            }
            &__minor {
                flex: 1 1 100%;
                align-self: flex-start;
                font-size: 13px;
                margin-top: 5px;
                font-weight: normal;
                text-align: center;
                font-family: $secondary-font-face;
                text-transform: none;
            }
        }

        .mdl-dialog__content{
          padding: 0 0 24px 24px;
          .material-icons{
            font-size: 18px;
          }
          p{
            font-size: 14px;
            line-height: 20px;
          }
        }
    }
      .mdl-dialog__actions{
        display: flex;
        flex-direction: column;
        align-content: center;
        .mdl-checkbox__label {
            font-size: 14px;
            font-family: $secondary-font-face;
        }
        .mdl-button{
          min-height: 45px;
          height: auto;
        }
      }
}

.select2-dropdown-container {

    .select2 {
        outline-style: none;
    }
    .country-options-container .select2-container, 
    .province-options-container .select2-container {
        flex: 1 1 100%;
        width: 100% !important;
        padding-top: 20px;

        .select2-selection {
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-radius: 0px;
        }
    }
    .select2-container {
        box-sizing: border-box;
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
    }
}

.dialog-address-modal {
    .select2-selection__arrow {
        top: 21px !important;
    } 
    .mdl-textfield {
        width: 100%;
    }
    .add-button-container {
        display: flex;
        justify-content: center;
    }

    .mdl-dialog__actions {
        @include mq("tablet", "max"){
        // flex-flow: column-reverse;
        justify-content: center;
        }

        .mdl-cell {
            @include mq("tablet", "max"){
                display: flex;
                justify-content: center;
            }
        }
    }
}

.mdl-radio.is-checked {

    .mdl-radio__outer-circle {
        border: 2px solid $brand-color;
    }

    .mdl-radio__inner-circle {
        background: $brand-color;
    }
}

.header-nav-container--landing {
	background: unset !important;
	.main-nav {
		margin-top: 50px;
	}
    @include mq("tablet", "min") {         //920px
        height: 122px;
    }
} 

.landing {

    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: 1024px;
    justify-content: space-evenly; 
    align-items: center;
    margin: 0 auto;
 
      @include mq("tablet", "max")  {
          flex-direction: column;
     }   

    
     margin-top: 122px;
      @include mq("tablet", "min") {         //920px
        margin-top: 165px auto; 
         
    } 

    &__site {

        flex: 1;
        height: 407px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background-size: cover !important;
        background-color: white !important;

        background-repeat: no-repeat !important;

         @include mq("tablet", "max")  {
         width: 100%;
     	}   

      

      	h1 {
				font-size: 5vw;
				font-weight: 300;
				color: #157C70;
				text-transform: capitalize;
				line-height: 1;
				padding-bottom: 38px;
 
				@include mq("desktop", "min") {
					font-size: 61px;
				}

			}
			p {
				font-size: 24px;
				font-weight: 300;
				color: #766F67;
				padding-bottom: 38px;    
				 @include mq("tablet-smaller", "max") {
					text-align: center;
				 }		
			}
        button {

        }

        &--public {

            background: url('../images/spot-fam.png')
            
        }

        &--corporate {

             background: url('../images/spot-pine.png') 

        }

    }


}


 