/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)

 .about-us {
	background: white;
	border-radius: 5px;
	padding: 12px 0px;
	max-width: none;
	text-align: center;
	line-height: 21px;
	font-family: $standard-font-face;

	@include mq("phone-wide", "max"){
	    padding: 20px;  
	}
	
	p{
// 		color: #353535;
// 		font-family: Nunito;
// 		font-size: 14px;
// 		font-weight: 300;
// 		line-height: 21px; 
	}

	.copy {
		max-width: 708px;
    	margin: 0 auto;

		@include mq("tablet-small", "max") {	
			// margin-left: 30%;
			background-color: rgba(255, 255, 255, 0.6);
			padding-left: 20px;
			padding-right: 20px;
		}
		
		@include mq("phone-wide", "max"){
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	.title {
		padding-top: 10px;
		padding-bottom: 0px;
		letter-spacing: 0;
		font-size: 34px;
		text-transform: none;
		color: #00245D;
		font-size: 30px;
		margin-bottom: 12px;

		@include mq("phone-wide", "max"){
	       line-height: 1.15;
	       padding: 10px 0px;  
	    }


	}
}
