/*
 * Flex UX Framework
 *
 * Filename: _breakpoints.scss
 * Type:     Commonly Shared Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 
$breakpoints: (
    "phone-smallest": 320px,
    "phone-small":    340px,
    "phone":          400px, // grid-cells 1 across
    "phone-wide":     480px, // grid-cells 2 across
    "tablet-smaller": 648px,
    "tablet-small":   768px,
    "tablet":         920px,
    "desktop":        1024px,
    "desktop-wide":   1374px // increase size of grid-cells
);

/* Quick copy and paste breakpoints
@include mq("phone-smallest", "max") //320px
@include mq("phone-smallest", "min") //320px
@include mq("phone-small", "max")    //340px
@include mq("phone-small", "min")    //340px
@include mq("phone", "max")          //400px
@include mq("phone", "min")          //400px
@include mq("phone-wide", "max")     //480px
@include mq("phone-wide", "min")     //480px
@include mq("tablet-small", "max")   //768px
@include mq("tablet-small", "min")   //768px
@include mq("tablet", "max")         //920px
@include mq("tablet", "min")         //920px
@include mq("desktop", "max")        //1024px
@include mq("desktop", "min")        //1024px
@include mq("desktop-wide", "max")   //1374px
@include mq("desktop-wide", "min")   //1374px
*/

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            @if $width != phone-smallest {
                $width: $width - 1px;
            }
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

// Example usage

// .site-header {
//     padding: 2rem;
//     font-size: 1.8rem;
//     @include mq('tablet-wide') {
//         padding-top: 4rem;
//         font-size: 2.4rem;
//     }
// }
