/*
 * Flex UX Framework
 *
 * Filename: checkout-payment-shipping.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container .shipping-row {
    display: flex;
    box-sizing: border-box;

    &:last-child {
    	padding-bottom: 0 !important;
    }
}

.checkout-container .shipping-methods {
    display: flex;
    flex: 12 12 60%;
    align-items: center;
    flex-flow: column;
}

.checkout-container h2 {
    @include mq("tablet", "max"){
        padding: 10px;
    }
}

@include mq("tablet", "min"){
	.checkout-container .shipping-row {
    	padding: 20px;
    	padding-bottom: 10px !important;
	}
}
.checkout-container .shipping-icon {
    display: flex;
    width: 22px;
    align-self: center;
    flex-flow: column;

    .material-icons{
        font-size: 18px;
    }
}
.checkout-payment .checkout-main .shipping-address-info {
	
	.shipping-icon {
	    float: left;
	}
	
	address {
	    display: inline-block;
	    max-width: 80%;
	    font-size: 14px;
	    line-height: 16px;
	    letter-spacing: 0;

    	.address-line {
 			display: inline-block;
    		width: 100%;
            font-family: $secondary-font-face;
		}
	}
}
.checkout-container address .address-line--title {
    font-weight: normal;
    font-family: $standard-font-face !important;
}
.checkout-payment .shipping-method-warning {
    box-sizing: border-box;
    width: 100%;
    padding-left: 50%;
    padding-right: 20px;
    padding-bottom: 10px;
    text-align: left;
    line-height: 15px;
}
.checkout-container .checkout-main-top--payment .item-listings .item-listings-header {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    color: #353535;
    height: 50px;
    line-height: 50px;
}

.checkout-container .checkout-main-top--payment .item-listings .mdl-collapse__content-wrapper{
    .item-line{
        display: block;
        margin-bottom: 3px;
        font-weight: normal;
    }
}
@include mq("tablet", "min"){
	.checkout-container .shipping-row {
	    padding: 20px;
	    // padding-bottom: 10px !important;
	}
}
.checkout-container .shipping-row--items {
    padding-left: 0 !important;
    padding-right: 0 !important;
    flex: 1 1 100%;
}

.checkout-container .checkout-main-top--payment .item-listings .item-listings-header i {
    float: right;
    margin-right: 20px;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
}
.checkout-container .checkout-main-top--payment .item-listings {
    flex: 1 1 100%;
    background: #FFF !important;
    .mdl-collapse--opened {
        background-color: #FFF;
        border-top: none;
    }
}

@include mq("tablet", "max"){
    .checkout-payment .shipping-method-warning {
        padding-left: 20px;
    }
    .checkout-container .shipping-row {
        flex-wrap: wrap;
        padding: 10px;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}


.checkout-container .checkout-main-top--payment .item-listings .items-accordion {
    padding-left: 20px;
}

.item-lines {
	padding: 10px;
}