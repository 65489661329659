/*
 * Flex UX Framework
 *
 * Filename: product_tabs.scss
 * Type:     Component Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.products-tab-bar {
    margin: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
    background: white;
    border-radius: 5px;
    height: 52px;
    margin-left: auto;
    margin-right: auto;
    max-width: 948px;
//     box-shadow: $box-shadow;

//     .mdl-tabs.is-upgraded .mdl-tabs__tab.is-active

  a.mdl-tabs__tab {
    font-family: $standard-font-face;
	height: 100%;
	line-height: 52px;
	font-size: 16px; 
	color: #353535;
	font-weight: normal; 
	text-decoration: none; 
	
	&.is-active { 
// 		color: #766F6A !important;
// 		font-size: 18px !important;
	}
	
  }

  .mdl-tabs__tab {
    padding: 0px 15px;
    transition: background 0.15s;
    font-size: 16px;
    font-family: $standard-font-face;
    border: 1px solid #F2F2F2;
    background-color: #FFF;
    border: 0;
    color: black;

    .mdl-ripple {
      background: #ADADAD !important;
      background-color: rgb(173, 173, 173);
      background-color: #ADADAD !important;
    }
  }

  .mdl-tabs__tab::after {
    background: $brand-color !important; 
    height: 3px !important;
  }

  .mdl-tabs__tab:hover {
    background-color: #E8E8E8;
  }

}

.container{
    border-radius: 5px;
    background: white;
}

.dropdown-container {
    position: relative;
    height:52px;
    .dropdown-arrow {
      position: absolute;
      right: 15px;
      top: 12px;
      pointer-events: none;
      transform: rotate(90deg);
      color: $brand-color;
    }

    select {
      height: 52px;
      line-height: 52px;
      width: 100%;
      border-radius: 5px;
      padding: 0;
      font-size: 17px;
      font-weight: normal;
      text-transform: uppercase;
      border: none;
      background: white;
      cursor: pointer;
      text-align: center;
      text-align-last: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      padding-left: 15px;
      width: calc(100% - 15px);
    }
  
}


