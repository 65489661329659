/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                      #0033A0; 
$brand-color-secondary:            #0033A0;  
$brand-color-btn-primary:           #0033A0;
$brand-color-btn-primary-hover:    #002980;
$brand-color-btn-secondary:         #303232;   
$brand-color-btn-secondary-hover:  #000; 

$primary-font-color:                #353535;  
$h1-font-color:                     #00245D;
$h2-font-color:                     $primary-font-color;


//
// General: Font
// 

@font-face {
    font-family: 'ESKbook';
    src: url("../css/ESKlarheitGrotesk-Bk.woff") format('woff');
}
@font-face {
    font-family: 'ESKbold';
    src: url("../css/ESKlarheitGrotesk-Xbd.woff") format('woff');
}

$h1-font-size:                      30px;
$h2-font-size:                      21px;
$standard-font-face:               'ESKbold', urw-din, sans-serif;
$secondary-font-face:              'ESKbook', nunito, sans-serif; 
$drawer-font:                      'ESKbold', urw-din, sans-serif; 


//
// Header
// 

$header-background:                 #00205B; //$brand-color;
$header-height:                     115px;
$header-top-height:                 50px;
$header-main-height:                41px;

$header-tablet-height:              56px;
$header-phone-height:               56px;
$header-logo-padding:               10px;
$header-minicart-badge-color:       #9AC5E5;  
$header-minicart-badge-border:      1px solid #000104;
$header-top-link-hover-border:      2px solid #000104;
$header-icons-color:                white; //#fff;
$header-search-font-color:          $header-icons-color;
$header-search-underline-color:     $header-icons-color;
$box-shadow:                        0px 1px 4px rgba(0,0,0,0.15); 

//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #00205B; //$brand-color; 


//
// Footer
//

$footer-background:                #757575; 
