/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home {

 	.spotlight {
   		 background-color: white;
   		 box-shadow: inset 0px 0px 150px 0px rgba(0,0,0,0.1);
   		 margin-top: 115px; 
   		 display: flex; 
   		 flex-direction: row-reverse;
   		 align-items: center;
   		 justify-content: center;
   		 border-bottom: 1px solid #E3E1DF;
         background: url(../images/HOME_jet_desktop_425_1024.jpg);
         background-size: cover;
		 height: 425px;

   		  @media only screen and (max-width: 920px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
				min-height: 400px;    
   		 
   		 }  

   		 @include mq("tablet", "max") { 
   		 	flex-direction: column;
   		 }

   		 @include mq("tablet", "max") { 
			margin-top: $header-phone-height;		 
		}

		@media only screen and (max-width: 976px) {
			margin-left: -20%;			
		}

		@media only screen and (max-width: 768px) {
			background: url(../images/HOME_jet_desktop_425_1024.jpg);
			height: 425px;
			margin-left: -25%;			
		}

		@media only screen and (max-width: 615px) {	
			background: url(../images/HOME_jet_mobile_350_400.jpg) ;
			height: 350px;
			margin-left: -30%;	
		}

		@media only screen and (max-width: 400px) {
			height: 350px;	
			margin-left: -45%;
		}

		@media only screen and (max-width: 321px) {
			background: url(../images/HOME_jet_mobile_small_300_321.jpg);
			height: 300px;	
		}

   		 &__text {
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-left: -50%;
			align-items: left;
			max-width: 572px;
			height: 360px;
			
			z-index: 2;

             @media only screen and (min-width: 920px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
				min-width: 572px;  
   		 	} 

			@include mq("desktop", "min") {
				// align-items: flex-end;  	
			}

			 @include mq("tablet", "max") { 
// 				position: absolute; 
				margin-left: -20%;	
				margin-top: 30px;	
   		 	}
			
			@media only screen and (max-width: 1200px) {
				margin-left: -40%;
				margin-top: 50px;			
			}	

			@media only screen and (max-width: 976px) {
				margin-left: -5%;
				margin-top: 40px;			
			}	

			 @include mq("tablet-smaller", "max") {
   		 		justify-content: center;
   		 		align-items: center;
   		 		padding: 40px 100px 4px 100px;
   		 		text-align: left;
				margin-left: 24%;
				margin-top: 0px;
			//  background-color: rgba(255, 255, 255, 0.6);
			//  border: 20px solid rgba(255, 255, 255, 0.0);
			//  border-radius: 20px;
   			 }

			@media only screen and (max-width: 615px) {	
				// margin-left: 30%;
				background-color: rgba(255, 255, 255, 0.6);
				padding: 20px 80px 4px 80px;
			}
			
			@include mq("phone-small", "max") {
				padding: 10px 0px 4px 30px;
			}

   			 a {
				margin-top: 7%;
				
				@media only screen and (max-width: 835px) {
					text-align: center;		
				}

   			 } 

			h1 {
				font-size: 7vw;
				text-transform: capitalize;
				line-height: 75px; 
				padding-bottom: 10px;	
				color: #00245D;
				font-weight: bold;
				// text-shadow: 0px 0px 4px #FFF;
				
				padding: 10px; 
				text-align: left;
				max-width: 90%; 				

				@include mq("desktop", "min") {
					font-size: 65px;  					
				}

				@include mq("tablet", "min") {
                    text-align: left; 
				}

				@include mq("phone-wide", "max") {
                   padding-bottom: unset; 
                    line-height: 38px; 
					text-align: center;
					margin-left: 0px;
				}

				@media only screen and (max-width: 835px) {	
					text-align: center;		
					max-width: 100%;
					line-height: 50px;
				}
				
				@media only screen and (max-width: 615px) {	
					text-align: center;		
					max-width: 100%;
					line-height: 36px;
					max-width: 375px;
				}	

			}

			p {
				color: #00245D;
				font-family: $secondary-font-face;
				font-size: 20px;
// 				font-weight: 300; 
                font-weight: normal; 			
                margin-bottom: 24px;
				margin-top: 0px;
				line-height: 28px; 
				text-align: left;
				// text-shadow: 0px 0px 4px #FFF;
				// background-color: rgba(255, 255, 255, 0.7);
				padding: 10px;
				// border: 20px solid rgba(255, 255, 255, 0.0);
				@include mq("tablet-smaller", "max") {
					text-align: center;
					margin-left: 0px;
				}	

				@media only screen and (max-width: 835px) {
					text-align: center;		
				}		
			}

			.discount {
				font-weight: 400 !important; 
				font-size: 12px; 

				@include mq("desktop", "min") {
					margin-top: 70px;  
					
				}

			}
   		 }  
   		 &__img {
		    flex: 1;
			height: 100%;
			display: flex;
			justify-content: center;
			position: relative;
			width: 100%;
			z-index: 1;
			/* margin: 0 auto; */
			max-width: 620px;

			@include mq("desktop", "min") {
				align-items: flex-end;  			
			}

    		 @include mq("tablet", "max") { 
// 				opacity: 0.3;  
   		 	}

   		 	@media only screen and (max-width: 920px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
				display: none;   
   		 	 } 

   		 	 @media only screen and (min-width: 920px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
				min-width: 620px;  
   		 	 } 

			@include mq("tablet-smaller", "max") {
				opacity: 70%;
			}

    		// background: url("../images/spot-items.png");
    		
			img {
    			min-height: 100px;
    		
			}
			&__overlay {
				flex: 1;
				background: url(../images/spot-pine.png);
				background-size: cover;
				height: 61%;
				position: absolute;
				width: 72%;
				bottom: 0;
				right: 0;

			}
   		 }
 	}

 	main {
 		background: #f2f2f2;
 	}
 	
 	.page-body {
 		max-width: 972px;
 		margin:  9px auto auto;
 		padding: 12px;
 		background: inherit;

 		@include mq("phone-wide", "max") {
		    padding: 6px;
		    margin-top: 0;
		}
 	}
 }

.spotlight {

	.page-header {
		position: relative;
		padding-top: $header-height;

		@include mq("tablet", "max") {
    		padding-top: 56px;
		}
	}

}
